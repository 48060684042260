<template>
    <div class="vue-wiki-slide-header" :style="headerStyle">
        <div class="vertical-top vue-wiki-slide-header__caption" :style="captionStyle">
            <span class="vertical-middle" @click="editCaption">{{ captionText }}</span>
            <audio-toggle
                    :audio-caption="audioCaption"
                    v-if="audioCaption"
                    fa-class="far"
                    class="vertical-middle"
                    ref="audioCaption"
            ></audio-toggle>
            <i class="far fa-pencil wiki-slide-header__icon vertical-middle pointer"
               v-if="editMode && !slide.is_fixed"
               @click="editCaption"
               @keydown.enter="editCaption"
               v-tooltip="'Edit Title'"
               role="button"
               tabindex="0"
            ></i>
        </div>
    </div>
</template>

<script>
    import ScreenWidthAwareMixin from '../../../vue/mixins/ScreenWidthAwareMixin';

    export default {
        name: 'WikiDetailPageSlideHeader',
        mixins: [ScreenWidthAwareMixin],
        props: {
            slide: Object,
            editMode: Boolean,
            height: Number
        },
        computed: {
            headerStyle() {
                return { 'height': `${this.height}px` };
            },
            captionStyle() {
                return { 'line-height': `${this.height - 4}px` };
            },
            captionText() {
                if (!this.slide) {
                    return;
                }
                if (this.slide.caption) {
                    let symbolsCount = 20;
                    if (this.windowWidth > 350) {
                        symbolsCount = 25;
                    }
                    if (this.windowWidth > 400) {
                        symbolsCount = 30;
                    }
                    if (this.windowWidth > 500) {
                        symbolsCount = 35;
                    }
                    if (this.windowWidth > 600) {
                        symbolsCount = 40;
                    }
                    return this.$options.filters.cutName(this.slide.caption, symbolsCount);
                }
                if (this.editMode) {
                    if (!this.slide?.is_fixed) {
                        return 'Click to add title...';
                    } else {
                        return 'No title';
                    }
                } else {
                    return '';
                }
            },
            audioCaption() {
                return this.slide?.audio_caption;
            }
        },
        methods: {
            editCaption() {
                if (!this.editMode || this.slide.is_fixed) {
                    return;
                }
                this.$store.dispatch('WikiStore/editSlideTitle', this.slide);
            },
            hasAudioCaption() {
                return !!this.$refs.audioCaption;
            },
            toggleSound() {
                this.$refs?.audioCaption?.toggleSound();
            },
            stopSound() {
                this.$refs?.audioCaption?.stopSound();
            },
        }
    };
</script>

<style scoped lang="scss">
  @import "../styles/const";

  .vue-wiki-slide-header {
    text-align: center;
    width: 100%;
    vertical-align: top;
    overflow: hidden;

    &__caption {
      @media (max-width: $screen-xs-max) {
        font-size: 22px;
        line-height: 24px;
      }
      @media (max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
        font-size: 26px;
        line-height: 28px;
      }
      @media (min-width: $screen-md-min) {
        font-size: 30px;
        line-height: 32px;
      }
      font-weight: bold;
    }
  }
</style>
